dmx.Component('f7-app', {

    initialData: {
    },

    attributes: {
        'name': {
            type: String,
            default: 'Framework7'
        },

        'version': {
            type: String,
            default: '1.0.0'
        },

        'theme': {
            type: String,
            default: 'auto'
        },

        'auto-dark-theme': {
            type: Boolean,
            default: false
        },

        'params': {
            type: Object,
            default: {}
        }
    },

    methods: {
        alert: function(msg) {
            if (!this.f7) return;
            this.f7.dialog.alert(msg);
        },

        showPreloader: function(color) {
            if (!this.f7) return;
            this.f7.preloader.show(color);
        },

        hidePreloader: function() {
            if (!this.f7) return;
            this.f7.preloader.hide();
        },

        showProgress: function(color) {
            if (!this.f7) return;
            this.f7.progressbar.show(color);
        },

        setProgress: function(progress, duration) {
            if (!this.f7) return;
            this.f7.progressbar.set(progress, duration);
        },

        hideProgress: function(color) {
            if (!this.f7) return;
            this.f7.progressbar.hide();
        }
    },

    render: function (node) {
        var theme = this.props.theme;
        if (theme == 'auto' && document.location.search.indexOf('theme=') >= 0) {
            theme = document.location.search.split('theme=')[1].split('&')[0];
        }
        
        this.f7 = dmx.f7.init(node, Object.assign(this.props.params, {
            id: 'io.framework7.' + this.name,
            name: this.props.name,
            version: this.props.version,
            theme: theme,
            autoDarkTheme: this.props['auto-dark-theme'],
            view: {
                stackPages: true
            }
        }));

        this.$parse();
    }

});