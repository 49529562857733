dmx.Actions({
    'f7.dialog.alert': function(options) {
        var text = this.parse(options.text);
        var title = this.parse(options.title);

        return new Promise(function(resolve) {
            Framework7.instance.dialog.alert(text, title, resolve);
        });
    },

    'f7.dialog.confirm': function(options) {
        var self = this;
        var text = this.parse(options.text);
        var title = this.parse(options.title);

        return new Promise(function(resolve) {
            Framework7.instance.dialog.confirm(text, title, function() {
                if (options.then) {
                    resolve(self._exec(options.then)).then(function() { return true; });
                } else {
                    resolve(true);
                }
            }, function() {
                if (options.else) {
                    resolve(self._exec(options.else)).then(function() { return false; });
                } else {
                    resolve(false);
                }
            });
        });
    },

    'f7.dialog.prompt': function(options) {
        var text = this.parse(options.text);
        var title = this.parse(options.title);
        var defaultValue = this.parse(options.defaultValue);

        return new Promise(function(resolve) {
            Framework7.instance.dialog.prompt(text, title, function(value) {
                resolve(value);
            }, function() {
                resolve(null);
            }, defaultValue);
        });
    },

    'f7.dialog.login': function(options) {
        var text = this.parse(options.text);
        var title = this.parse(options.title);

        return new Promise(function(resolve) {
            Framework7.instance.dialog.login(text, title, function(username, password) {
                resolve({
                    username: username,
                    password: password
                });
            }, function() {
                resolve(null);
            });
        });
    },

    'f7.dialog.password': function(options) {
        var text = this.parse(options.text);
        var title = this.parse(options.title);

        return new Promise(function(resolve) {
            Framework7.instance.dialog.password(text, title, function(password) {
                resolve(password);
            }, function() {
                resolve(null);
            });
        });
    },

    'f7.dialog.preloader': function(options) {
        var title = this.parse(options.title);
        var color = this.parse(options.color);

        Framework7.instance.dialog.preloader(title, color);
    },

    'f7.dialog.close': function(options) {
        Framework7.instance.dialog.close();
    }
});