dmx.Component('f7-page', {

    initialData: {
        route: null
    },

    render: function (node) {
        this.$parse();
    }

});