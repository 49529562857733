dmx.Component('f7-view', {

    constructor: function (node, parent) {
        this.onPageInit = this.onPageInit.bind(this);
        this.onPageBeforeRemove = this.onPageBeforeRemove.bind(this);

        dmx.BaseComponent.call(this, node, parent);
    },

    methods: {

        navigate: function (url, options) {
            if (this.f7View.router) {
                this.f7View.router.navigate(url, options);
            }
        },

        back: function (url, options) {
            if (this.f7View.router) {
                this.f7View.router.back(url, options);
            }
        },

        refresh: function() {
            if (this.f7View.router) {
                this.f7View.router.refreshPage();
            }
        },

        clearHistory: function() {
            if (this.f7View.router) {
                this.f7View.router.clearPreviousHistory();
            }
        }

    },

    render: function (node) {
        this.f7View = Framework7.instance.views.create(node);
        this.f7View.on('pageInit', this.onPageInit);
        this.f7View.on('pageReinit', this.onPageReinit);
        this.f7View.on('pageBeforeRemove', this.onPageBeforeRemove);

        this.$parse();
    },

    onPageInit: function (page) {
        var Component = dmx.Component('f7-page');
        var child = new Component(page.el, this);
        child.set('route', page.route);
        this.children.push(child);
        this.set(child.name, child.data);
        page.dmxComponent = child;
    },

    onPageReinit: function (page) {
        if (page.dmxComponent) {
            page.dmxComponent.set('route', page.route);
        }
    },

    onPageBeforeRemove: function (page) {
        var index = this.children.findIndex(function (child) {
            return child.$node == page.el;
        });
        if (index != -1) {
            this.del(this.children[index].name);
            this.children.splice(index, 1);
        }
    },

    destroy: function () {
        if (this.f7View) {
            this.f7View.off('pageInit', this.onPageInit);
            this.f7View.off('pageReinit', this.onPageReinit);
            this.f7View.off('pageBeforeRemove', this.onPageBeforeRemove);
            if (this.f7View.destroy) this.f7View.destroy();
        }
    }

});